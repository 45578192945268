/* This is the base style. Assuming mobile-first approach, 
   start with flex-column for smaller screens. */
.container {
    display: flex;
    flex-direction: column;
}

/* This media query applies when the screen width is 1190px or more. 
     It changes the flex direction to row. */
@media (min-width: 1190px) {
    .container {
        flex-direction: row;
    }
}

/* Base styles for .container */
.container1 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

/* Media query for screen width of 1189px and below */
@media (max-width: 1189px) {
    .container1 {
        /* Ensures the container takes up the full viewport height */

        /* Centers children horizontally */
        align-items: center;
    }
}

/* Base styles for .container */
.container2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

/* Media query for screen width of 1189px and below */
@media (max-width: 1189px) {
    .container2 {
        /* Ensures the container takes up the full viewport height */

        /* Centers children horizontally */
        align-items: center;
    }
}