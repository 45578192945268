@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://fonts.googleapis.com/css?family=roboto");

body {
  font-family: "roboto", sans-serif;
} */
/* Add this inside your tailwind.css file or a custom CSS file */
.hide-scrollbar {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* For Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* For Safari and Chrome */
}
