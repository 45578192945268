@media (min-width: 1154px) and (max-width: 1500px) {

    .media-flexDirection {
        display: flex;
        flex-direction: row;
        /* Flex direction for screens between 411px and 640px */
    }
}

@media (min-width: 0px) and (max-width: 1154px) {

    .media-flexDirection {
        display: flex;
        flex-direction: column;
        /* Flex direction for screens up to 410px */
    }
}

@media (max-width: 1154px) {
    .flex-row-to-col {
        flex-direction: column;
    }

    .flex-row-to-col>.flex-child {
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 1rem;
        /* Add some spacing between the items in column layout */
    }

    .flex-row-to-col>.flex-child:last-child {
        margin-bottom: 0;
        /* Remove the bottom margin for the last child */
    }
}