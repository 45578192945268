/* Custom checkbox styles */
.custom-checkbox {
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    display: inline-block;
    position: relative;
}

.custom-checkbox:checked {
    background-color: #2ecc71;
    border: none;
}

.custom-checkbox:checked::after {
    content: url('/src/v2/assets/images/Checkbox.png');
    /* Make sure to replace this with the correct path to your image */
    position: absolute;
    width: 16px;
    /* Adjust the size accordingly */
    height: 16px;
}